@media print {
  
  // Do no show header, side nav, claim header or print buttons
  header, nav, app-claim-header, .print-button {
    display: none !important;
  }

  // Do not show 'Add Note' button on note page
  app-claim-note-list .add-record-link {
    display: none !important;
  }

  // Don't show filter links on history page
  app-claim-history-list .history-filters {
    display: none !important;
  }

  // Override screen setting on main to fill out to entire page width
  main {
    max-width: 100% !important;
  }

  // 1 inch margins on portrait reports
  .first-notice, .adverse-benefit {
    margin-left: 0.6in;
    margin-right: 0.6in;
  }

  // Adjust invoice for 1 inch margins
  .invoice {
    margin-left: 0.3in;
    margin-right: 0.3in;
  }

  .footer {
    display: none;
  }

  .claim > .table-grid-row {
    .sidebar {
      display: none !important;
    }

    .main {
      padding: 0;

      & > .header, & > .navigation {
        display: none;
      }
      width: 100%;
    }
  }

  /* Toasts should not print */
  #toast-container {
    display: none !important;
  }
}
