@import 'variables';

input, textarea {
  &.ng-touched.ng-invalid, form.ng-submitted &.ng-invalid {
    border: 1px solid red;
  }
}

label input {
  font-weight: normal;
}

.button {
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid $darkBtnColor;
  border-radius: 4px;
  padding: 5px 16px;
  color: #fff;
  cursor: pointer;
  background: $darkBtnColor;

  &:hover {
    text-decoration: none;
    background: #fff;
    color: $darkBtnColor;
    border: 1px solid $darkBtnColor;
  }

  &:disabled {
    text-decoration: none;
    background: transparent;
    color: lightgray;
    border: 1px solid lightgray;
  }

  &:focus {
    outline: none;
  }

  &.light-button {
    background: #fff;
    color: $darkBtnColor;
    border: 1px solid $darkBtnColor;

    &:hover {
      text-decoration: none;
      color: #fff;
      background: $darkBtnColor;
    }
  }
}

.header-form {
  display: flex;
  align-items: center;

  label {
    margin: 0 10px;

    display: flex;
    align-items: center;
  }

  input[type="checkbox"] {
    margin-right: 4px;
  }
}
