@import 'variables';

@import 'forms';
@import 'print';

html {
  min-height: 100%;
}

a {
  color: $darkBtnColor;
  cursor: pointer;

  &:hover {
    color: darken($darkBtnColor, 8%);
  }
}

// Header navigation for the Claims back links
.header-nav {
  padding-bottom: 3px;
  border-bottom: 1px solid #3DAEA2;
  font-size: 18px;
}

.clear {
  clear: both;
}

.center-text {
  text-align: center !important;
}

.right-text {
  text-align: right !important;
}

.left-text {
  text-align: left !important;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 20px 15px 0px 15px;
  background-color: #f5f5f5;
}


// Record component styles
.add-record-link {
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    color: darken($baseGreenColor, 5%);
  }
}

.empty-records-notice {
  list-style: none;
  text-align: center;
  font-size: 20px;
  color: #A4A4A4;
  padding-top: 80px;
}

.center {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.table-grid {
  display: table;
}

.table-grid-row {
  display: table-row;
}

.table-grid-cell {
  display: table-cell;
  padding-left: 20px;
  vertical-align: top;

  &:first-child {
    padding-left: 0;
  }
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid $baseGreenColor;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Bootstrap Overrides

/* Renders line breaks as wrapped lines */
.line-breaks {
  white-space: pre-wrap;
}

/* General add link */
.add-link {
  color: #000;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
}

/* Typeahead Styles */

.typeahead,
.tt-query,
.tt-hint {
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-highlight {
  color: #cc0000;
}

.tt-menu {
  width: 600px;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);

  .empty {
    padding: 10px;
  }
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;

  .value {
    font-size: 16px;
    display: block;
  }

  .description {
    font-size: 10pt;
    font-weight: normal;
  }

  border-bottom: 1px solid #D3D3D3;
}

.tt-suggestion:hover {
  cursor: pointer;
  background-color: #e6f3ff;
}

.tt-suggestion.tt-cursor {
  background-color: #e6f3ff;
}

.tt-suggestion p {
  margin: 0;
}

/* Form validation styles */

.input_invalid {
  color: red;
}

@media (max-width: 1200px) {
  .hidden-xs {
    display: none;
  }
}


/* Forces word wrapping on line breaks */
.word-wrap-all {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/* Capitalizes the first character of an area */
.capitalize {
   text-transform: capitalize;
}

// Special "card" interface for performing actions within the system
.action-card {
  display: block;
  padding: 6px;
  margin-bottom: 5px;
  border: 1px solid silver;

  .action-card-body {
    padding: 9px;

    h4 {
      font-size: 2rem;
      margin-bottom: 10px;
    }

    p {
      margin: 10px 0;
    }
  }
}

// Loading style for AJAX operations
.loading {
  background-color: #ffffcc;
  margin: 5px;
  padding: 3px;
}

.overlay {
  opacity: 0.8;
  background-color: #ccc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 50px;
  font-weight: bold;
  z-index: 1000;
  text-align: center;
  padding-top: 400px;
}

// global date input styles

input[type="date"].form-control {
  line-height: inherit;
}
