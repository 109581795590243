@use '@angular/cdk' as cdk;
@include cdk.overlay();

.cdk-overlay-backdrop {

  &.transparent-backdrop {
    background: transparent;
  }

}
